@use "../_variables/variables" as *;
@use "break-points" as *;
// font text
//---------------------------------------------------
//デフォルトフォントファミリー
@mixin set_font-family {
	font-family: -apple-system, BlinkMacSystemFont, #{map-get($font-set, font01)}, "Hiragino Sans", "ヒラギノ角ゴ ProN W3",
		"Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothicM, YuGothic, "メイリオ", Meiryo,
		sans-serif;
}

//font-family
@mixin f-family($fontname, $next: sans-serif) {
	$name: map-get($font-set, $fontname);
	font-family: #{$name}, $next;
}

//font-size
@mixin f-size($size: 14) {
	font-size: round-decimal(calc($size / 16), 2) + rem;
}

//font-weight
@mixin f-w($weight: 400) {
	font-weight: $weight;
}

//letter-spacing
@mixin l-sp($l-sp: 0.1em) {
	letter-spacing: $l-sp;
}
//line-height
@mixin line-h($height: 1.75) {
	line-height: $height;
}
//スマホ・タブレット・PCでのデフォルトフォントサイズ
@mixin f-base {
	font-size: floor-decimal(calc($f-sp / 16),4) * 100%;
	@include media-breakpoint-up(sm) {
		font-size:floor-decimal(calc($f-tb / 16),4) * 100%;
	}
	@include media-breakpoint-up(lg) {
		font-size: floor-decimal(calc($f-pc / 16),4) * 100%;
	}
}

@mixin f-em($em:16) {
	font-size:floor-decimal(calc($em / $f-sp),2) * 1em;
	@include media-breakpoint-up(sm) {
		font-size:floor-decimal(calc($em / $f-tb),2) * 1em;
	}
	@include media-breakpoint-up(lg) {
		font-size:floor-decimal(calc($em / $f-pc),2) * 1em;
	}
}


// font-size　用
$fontbreak: (
	xxs: 280, //デバイス幅の最小値
	xs: map-get($grid-num, xs),
	sm: map-get($grid-num, sm),
	md: map-get($grid-num, md),
	lg: map-get($grid-num, lg),
	xl: map-get($grid-num, xl)
) !default;
@function fs_clamp($name, $minsize, $maxsize) {
	$min_width: map-get($fontbreak, $name);
	$val_min: $minsize * 1px;
	$val_vw: round-decimal(calc($minsize / $min_width) * 100) * 1vw;
	$val_max: $maxsize * 1px;
	@return clamp(#{$val_min}, #{$val_vw}, #{$val_max});
}
@mixin fs_xxs($minsize, $min_width) {
	font-size: fs_clamp(xxs, $minsize, $min_width);
}
@mixin fs_xs($minsize, $min_width) {
	font-size: fs_clamp(xs, $minsize, $min_width);
}
@mixin fs_sm($minsize, $min_width) {
	font-size: fs_clamp(sm, $minsize, $min_width);
}
@mixin fs_md($minsize, $min_width) {
	font-size: fs_clamp(md, $minsize, $min_width);
}
@mixin fs_lg($minsize, $min_width) {
	font-size: fs_clamp(lg, $minsize, $min_width);
}
@mixin fs_xl($minsize, $min_width) {
	font-size: fs_clamp(xl, $minsize, $min_width);
}

@mixin f-s_xxs($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - 280px) / #{map-get($grid-num,xl) - 280});
}
@mixin f-s_xs($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw -  #{map-get($grid-num,xs)}px) / #{map-get($grid-num,xl) - map-get($grid-num,xs)});
}
@mixin f-s_sm($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num,sm)}px) / #{map-get($grid-num,xl) - map-get($grid-num,sm)});
}
@mixin f-s_md($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num,md)}px) / #{map-get($grid-num,xl) - map-get($grid-num,md)});
}
@mixin f-s_lg($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num,lg)}px) / #{map-get($grid-num,xl) - map-get($grid-num,lg)});
}
@mixin f-s_xl($size,$wid) {
	font-size:calc(#{$size}rem + #{$wid} * (100vw - #{map-get($grid-num,xl)}px) / #{map-get($grid-num,xxl) - map-get($grid-num,xl)});
}



/* common-title
-----------------------------------------------------*/
@mixin cont-tit {
	//コンテンツタイトル
	margin-bottom: calc(2rem + 0.95em);
	line-height: 1.4;
	b {
		@include f-s_xs(2, 32);
		@include l-sp(0.03em);
		@include f-family(font03);
		@include f-w(500);
		display: block;
		color: $main_c;
		@include media-breakpoint-up(xl) {
			@include f-size(64);
		}
	}
	span {
		@include f-family(font03);
		@include f-s_xs(1.14, 8);
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
}

@mixin cont-tit2 {
	//コンテンツタイトル
	margin-bottom: calc(2rem + 0.6em);
	line-height: 1.4;
	b {
		@include f-s_xs(1.5, 24);
		@include l-sp(0.03em);
		@include f-family(font03);
		@include f-w(500);
		display: block;
		color: $main_c;
		@include media-breakpoint-up(xl) {
			@include f-size(48);
		}
	}
	span {
		display: block;
		@include f-family(font03);
		@include f-s_xs(1, 8);
		@include f-w(500);
		@include media-breakpoint-up(xl) {
			margin-top: 5px;
			@include f-size(26);
		}
	}
}
