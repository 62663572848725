//===================================================
//	font setting
//===================================================
//font-size
$f-sp: 14; //スマホ用フォントサイズ
$f-tb: 15; //タブレット用フォントサイズ
$f-pc: 16; //PC用フォントサイズ

//font-family
$font-set: (
  font01: "'Noto Sans JP'",
  //メインフォントファミリー
  font02: "'Roboto'",
  //英字フォントファミリー
  font03: "'Noto Serif JP'",
  //その他ファミリー
);

/* webfont 変数　*/
$icons: (
  arrow1_top: "\e316",
  arrow1_right: "\e315",
  arrow1_bottom: "\e313",
  arrow1_left: "\e314",
  arrow2_top: "\e907",
  arrow2_right: "\e901",
  arrow2_bottom: "\e906",
  arrow2_left: "\e902",
  home: "\e90c",
  phone: "\e909",
  mail: "\e911",
  link: "\e900",
  pdf: "\e903",
  tag: "\e904",
  lock: "\e90f",
  search: "\e905",
  search-plus: "\e908",
  facebook1: "\e90a",
  facebook2: "\e910",
  x: "\e90d",
  instagram: "\e90b",
  line: "\e90e",
  youtube: "\ea9d"
) !default;

@mixin icon($icon: false, $position: before) {
  @if $position == both {
    $position: ":before,:after";
  }
  &:#{$position} {
    @if $icon {
      content: "#{map-get($icons, $icon)}";
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  @content;
}
