@charset "UTF-8";
/* webfont 変数　*/
/* common-title
-----------------------------------------------------*/
/* ofi */
@media (min-width: 1920px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1536px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1024px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1919px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1535px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1023px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1023px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1024px) and (max-width: 1535px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1536px) and (max-width: 1919px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

index.css

=====================================================================*/
/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
}
@media (min-width: 768px) {
  .main-view {
    height: 100vw;
  }
}
@media (min-width: 1024px) {
  .main-view {
    height: 100vh;
  }
}
@media (max-width: 767px) {
  .main-view {
    height: 150vw;
  }
}
.main-view .l-cont {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%;
  z-index: 3;
}
@media (max-width: 575px) {
  .main-view .l-cont {
    justify-content: center;
  }
}

.main-view__bg {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .main-view__bg {
    height: 100vw;
  }
}
@media (min-width: 1024px) {
  .main-view__bg {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .main-view__bg {
    height: 150vw;
  }
}
.main-view__bg__img {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .main-view__bg__img {
    height: 100vw;
  }
}
@media (min-width: 1024px) {
  .main-view__bg__img {
    height: 100vh;
  }
}
@media (max-width: 767px) {
  .main-view__bg__img {
    height: 150vw;
  }
}
.main-view__bg__img__inner {
  height: 100%;
}
.main-view__bg__img__inner img {
  transform: scale(1.1, 1.1);
}

.main-view__inner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.main-view__txt {
  z-index: 4;
  width: 100%;
}

.main-view {
  position: relative;
}
.main-view__filter {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
  opacity: 0;
}
.main-view .l-cont {
  z-index: 3;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: none;
  padding: 0;
}
.main-view__catch {
  text-align: center;
  width: 100%;
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
}
.main-view__catch__inner {
  color: #21374e;
  background: transparent;
  padding: 1.5rem 4% 2rem;
  display: inline-block;
  transition: all 0.6s ease-in 0s;
}
@media (min-width: 1024px) {
  .main-view__catch__inner {
    padding: 1.5rem 5rem 2rem;
  }
}
.main-view__catch__inner.display {
  color: #fff;
  background: rgba(33, 55, 78, 0.7);
}
.main-view__catch__inner em {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.05;
  font-style: normal;
  display: block;
  font-size: calc(2rem + 30 * (100vw - 376px) / 1064);
  line-height: 1;
}
@media (min-width: 1536px) {
  .main-view__catch__inner em {
    font-size: 3.75rem;
  }
}
.main-view__txt {
  padding: 3.5rem 0 4rem 5rem;
  background-color: rgba(33, 55, 78, 0.91);
  max-width: 1030px;
}

.catch-01 {
  position: relative;
  text-transform: uppercase;
  font-family: 'Noto Serif JP', serif;
  font-size: clamp(30px, 10.71vw, 84px);
  color: #fff;
  line-height: 1.5;
  letter-spacing: 0.05em;
  font-weight: 400;
}
@media (min-width: 1536px) {
  .catch-01 {
    font-size: 5.25rem;
  }
}
@media (max-width: 575px) {
  .catch-01 {
    text-align: center;
  }
}
.catch-01__letter {
  display: inline-block;
  line-height: 1em;
}

.catch-02, .catch-03 {
  position: relative;
  display: block;
  padding-top: 0.1em;
  padding-bottom: 0.15em;
  overflow: hidden;
  font-size: clamp(10px, 2.66vw, 22px);
  color: #fff;
  letter-spacing: 0.13em;
  line-height: 1.5;
  font-weight: 500;
}
@media (min-width: 1536px) {
  .catch-02, .catch-03 {
    font-size: 1.38rem;
  }
}
@media (max-width: 575px) {
  .catch-02, .catch-03 {
    text-align: center;
  }
}
.catch-02__letter, .catch-03__letter {
  display: inline-block;
  line-height: 0.1em;
}

/* scroll */
.scroll-line {
  position: absolute;
  left: min(2%, 4rem);
  bottom: -2.5rem;
  writing-mode: vertical-rl;
  z-index: 4;
}
.scroll-line a {
  text-decoration: none !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  transition: all 0.3s ease 0s;
}
.scroll-line a:hover {
  color: #21374e;
}
.scroll-line__txt {
  letter-spacing: 0;
}
.scroll-line span {
  content: "";
  inline-size: 5rem;
  block-size: 1px;
  background: transparent;
  display: block;
  position: relative;
  overflow: hidden;
}
.scroll-line span::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: #21374e;
  transition: all 0.3s ease 0s;
  block-size: 1px;
  inline-size: 100%;
  animation: scroll-line 1.75s cubic-bezier(1, 0, 0, 1) infinite;
}

@keyframes scroll-line {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  51% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}
.simpleParallax {
  height: 100%;
}

/* common
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.about-cont {
  position: relative;
  padding-top: clamp(6rem, 10vw, 10rem);
  padding-bottom: clamp(6rem, 10vw, 10rem);
  overflow: hidden;
}
@media (min-width: 1024px) {
  .about-cont {
    padding-bottom: clamp(10rem, 12vw, 14rem);
  }
}
.about-cont__inner {
  position: relative;
  display: flex;
}
@media (max-width: 1023px) {
  .about-cont__inner {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column-reverse;
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media (max-width: 767px) {
  .about-cont__inner {
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media (min-width: 1024px) {
  .about-cont__txt {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (min-width: 1024px) {
  .about-cont__txt__inner {
    padding-right: 8vw;
    padding-left: 6vw;
    width: min(50vw,740px);
  }
}
@media (min-width: 1536px) {
  .about-cont__txt__inner {
    padding-left: 48px;
  }
}
@media (min-width: 1920px) {
  .about-cont__txt__inner {
    width: min(60vw,800px);
  }
}

.about-cont__img {
  position: relative;
}
@media (min-width: 1024px) {
  .about-cont__img {
    width: 50%;
  }
}
@media (max-width: 1023px) {
  .about-cont__img {
    padding-bottom: clamp(2.5em, 4vw, 4rem);
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 1023px) {
  .about-cont__img::after {
    position: absolute;
    display: block;
    left: 10%;
    bottom: 0;
    content: "";
    width: 100%;
    height: calc(100% - 6vw);
    background: #21374e;
    z-index: 0;
  }
}
@media (min-width: 1024px) {
  .about-cont__img__inner {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 1023px) {
  .about-cont__img__inner {
    width: 100%;
  }
}
.about-cont__img__inner figure {
  position: relative;
  z-index: 2;
}
@media (min-width: 1024px) {
  .about-cont__img__inner figure {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
    overflow: visible;
    width: min(45vw,864px);
  }
  .about-cont__img__inner figure::before {
    content: "";
    display: block;
    padding-top: 80%;
    position: relative;
  }
  .about-cont__img__inner figure > div, .about-cont__img__inner figure > figure, .about-cont__img__inner figure > a, .about-cont__img__inner figure > picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .about-cont__img__inner figure {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }
  .about-cont__img__inner figure::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .about-cont__img__inner figure > div, .about-cont__img__inner figure > figure, .about-cont__img__inner figure > a, .about-cont__img__inner figure > picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.about-cont__img__inner figure > div {
  position: relative;
  z-index: 2;
}
.about-cont__img__inner figure::after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background: #21374e;
  z-index: 1;
}
@media (min-width: 1024px) {
  .about-cont__img__inner figure::after {
    left: 12%;
    top: 6vw;
  }
}
@media (min-width: 1920px) {
  .about-cont__img__inner figure::after {
    top: 6rem;
  }
}
.about-cont__img img {
  width: 100%;
  display: block;
}

.freecont01-cont {
  overflow: hidden;
  position: relative;
  background: #F8F9FA;
  padding-top: clamp(4rem, 8vw, 10rem);
  padding-bottom: clamp(5rem, 9vw, 12rem);
}
.freecont01-cont__tit {
  margin-bottom: calc(2rem + 0.95em);
  line-height: 1.4;
  margin-bottom: calc(2rem + 2em);
  text-align: center;
}
.freecont01-cont__tit b {
  font-size: calc(2rem + 32 * (100vw - 376px) / 1064);
  letter-spacing: 0.03em;
  font-family: 'Noto Serif JP', sans-serif;
  font-weight: 500;
  display: block;
  color: #21374e;
}
@media (min-width: 1536px) {
  .freecont01-cont__tit b {
    font-size: 4rem;
  }
}
.freecont01-cont__tit span {
  font-family: 'Noto Serif JP', sans-serif;
  font-size: calc(1.14rem + 8 * (100vw - 376px) / 1064);
}
@media (min-width: 1536px) {
  .freecont01-cont__tit span {
    font-size: 1.5rem;
  }
}
.freecont01-cont__txt {
  margin-bottom: clamp(3rem, 4vw, 4rem);
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

/* freecontent02 */
.freecont02-cont {
  overflow: hidden;
  position: relative;
  padding-top: clamp(4rem, 8vw, 10rem);
  padding-bottom: clamp(5rem, 9vw, 12rem);
}
.freecont02-cont__tit {
  margin-bottom: calc(2rem + 0.95em);
  line-height: 1.4;
  margin-bottom: calc(2rem + 2em);
  text-align: center;
}
.freecont02-cont__tit b {
  font-size: calc(2rem + 32 * (100vw - 376px) / 1064);
  letter-spacing: 0.03em;
  font-family: 'Noto Serif JP', sans-serif;
  font-weight: 500;
  display: block;
  color: #21374e;
}
@media (min-width: 1536px) {
  .freecont02-cont__tit b {
    font-size: 4rem;
  }
}
.freecont02-cont__tit span {
  font-family: 'Noto Serif JP', sans-serif;
  font-size: calc(1.14rem + 8 * (100vw - 376px) / 1064);
}
@media (min-width: 1536px) {
  .freecont02-cont__tit span {
    font-size: 1.5rem;
  }
}
.freecont02-cont__txt {
  margin-bottom: clamp(3rem, 4vw, 4rem);
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

/* works-cont */
.works-cont {
  padding-top: clamp(4rem, 8vw, 10rem);
  padding-bottom: clamp(5rem, 9vw, 12rem);
}
.works-cont .l-cont {
  position: relative;
}
.works-cont__txt {
  margin-bottom: calc(2rem + 0.75em);
}
@media (min-width: 1536px) {
  .works-cont__txt {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 1536px) {
  .works-cont__txt .cont-tit {
    padding-right: 3em;
  }
}
@media (max-width: 1535px) {
  .works-cont__txt .cont-tit {
    margin-bottom: calc(1rem + 0.3em);
  }
}
.works-cont .btn-wrap {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 360px;
}
.works-cont .btn-wrap button {
  font-size: 1.28em;
}
@media (min-width: 576px) {
  .works-cont .btn-wrap button {
    font-size: 1.2em;
  }
}
@media (min-width: 1024px) {
  .works-cont .btn-wrap button {
    font-size: 1.12em;
  }
}

/*---------------------------------------------------------
 staff
---------------------------------------------------------*/
.staff-cont {
  padding-top: clamp(6rem, 10vw, 10rem);
  padding-bottom: clamp(6rem, 10vw, 10rem);
  position: relative;
  background-color: #F8F9FA;
  background-image: radial-gradient(#c9ced2 8.33%, transparent 8.33%);
  background-size: 24px 24px;
  z-index: 1;
}
@media (max-width: 575px) {
  .staff-cont {
    padding-top: clamp(10rem, 40vw, 20rem);
  }
}
.staff-cont__llst > *:not(:last-of-type) {
  padding-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 768px) {
  .staff-cont__llst > *:not(:last-of-type) {
    padding-bottom: clamp(2rem, 6vw, 6rem);
  }
}
@media (min-width: 768px) {
  .staff-cont .none-txt {
    text-align: center;
  }
}

/* staff-box */
.staff-box {
  padding-top: clamp(2rem, 3vw, 3rem);
}
.staff-box:not(:last-of-type) {
  padding-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 768px) {
  .staff-box__inner.is-left {
    display: grid;
    grid-template-columns: 28.33% 60%;
    grid-template-rows: auto 1fr;
    column-gap: 11.66%;
  }
  .staff-box__inner.is-left .staff-box__img {
    grid-column: 1/2;
  }
  .staff-box__inner.is-left .staff-box__tit {
    grid-column: 2/3;
  }
  .staff-box__inner.is-left .staff-box__txt {
    grid-column: 2/3;
  }
}
@media (min-width: 768px) {
  .staff-box__inner.is-right {
    display: grid;
    grid-template-columns: 60% 28.33%;
    grid-template-rows: auto 1fr;
    column-gap: 11.66%;
  }
  .staff-box__inner.is-right .staff-box__img {
    grid-column: 2/3;
  }
  .staff-box__inner.is-right .staff-box__tit {
    grid-column: 1/2;
  }
  .staff-box__inner.is-right .staff-box__txt {
    grid-column: 1/2;
  }
}
@media (max-width: 767px) {
  .staff-box__inner {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}
.staff-box__tit {
  color: #000;
  font-weight: 500;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
  border-bottom: 1px solid #c9ced2;
  font-size: calc(1.07rem + 6 * (100vw - 376px) / 1064);
}
@media (min-width: 768px) {
  .staff-box__tit {
    grid-row: 1/2;
    font-size: calc(1.25rem + 4 * (100vw - 1000px) / 440);
  }
}
@media (min-width: 1536px) {
  .staff-box__tit {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .staff-box__img {
    grid-row: 1/3;
  }
}
@media (max-width: 767px) {
  .staff-box__img {
    width: calc(100% - 1rem);
    margin-bottom: 2rem;
  }
}
.staff-box__img__inner {
  display: block;
  position: relative;
}
.staff-box__img__inner.light-box {
  overflow: visible !important;
}
@media (max-width: 767px) {
  .staff-box__img__inner {
    width: 100%;
    height: 66.66%;
  }
}
.staff-box__img__inner::before {
  position: absolute;
  top: 1rem;
  left: 1rem;
  content: "";
  background: #21374e;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .staff-box__img__inner::before {
    top: 2rem;
    left: 2rem;
  }
}
@media (min-width: 768px) {
  .staff-box__img__inner figure {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }
  .staff-box__img__inner figure::before {
    content: "";
    display: block;
    padding-top: 100%;
    position: relative;
  }
  .staff-box__img__inner figure > div, .staff-box__img__inner figure > figure, .staff-box__img__inner figure > a, .staff-box__img__inner figure > picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .staff-box__img__inner figure {
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 1;
  }
  .staff-box__img__inner figure::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .staff-box__img__inner figure > div, .staff-box__img__inner figure > figure, .staff-box__img__inner figure > a, .staff-box__img__inner figure > picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .staff-box__txt {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}

/*---------------------------------------------------------
 qa
---------------------------------------------------------*/
.qa-cont {
  padding-top: clamp(6rem, 10vw, 10rem);
  padding-bottom: clamp(6rem, 10vw, 10rem);
  background: #f6f6f6;
}
.qa-cont__llst {
  counter-reset: qa-num;
}
.qa-cont__llst > *:not(:last-of-type) {
  padding-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 768px) {
  .qa-cont__llst > *:not(:last-of-type) {
    padding-bottom: clamp(2rem, 6vw, 6rem);
  }
}
@media (min-width: 768px) {
  .qa-cont .none-txt {
    text-align: center;
  }
}

.qa-box__tit {
  padding-left: calc(2em + 2rem);
  color: #000;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
  border-bottom: 1px solid #c9ced2;
}
@media (min-width: 1536px) {
  .qa-box__tit {
    padding-left: 4.5rem;
  }
}
.qa-box__tit span {
  font-weight: 500;
  font-size: calc(1.14rem + 4 * (100vw - 376px) / 1064);
}
@media (min-width: 1536px) {
  .qa-box__tit span {
    font-size: 1.38rem;
  }
}
.qa-box__tit::before {
  counter-increment: qa-num;
  content: "Q." counter(qa-num);
  position: absolute;
  font-family: 'Roboto', sans-serif;
  top: 0.2em;
  left: 0;
  line-height: 1;
  color: #21374e;
  font-size: calc(2rem + 8 * (100vw - 376px) / 1064);
  letter-spacing: 0;
}
@media (min-width: 1536px) {
  .qa-box__tit::before {
    font-size: 2.25rem;
  }
}
/* message */
.message-cont {
  padding-top: clamp(6rem, 10vw, 10rem);
  padding-bottom: clamp(8rem, 20vw, 24rem);
  position: relative;
  background: #fff;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .message-cont {
    background: #21374e;
  }
}
@media (max-width: 575px) {
  .message-cont.is-imguse {
    padding-top: clamp(10rem, 40vw, 20rem);
  }
}
@media (max-width: 575px) {
  .message-cont.is-imguse .message-cont__inner {
    padding-top: clamp(11rem, 50vw, 16rem);
  }
}
.message-cont .l-cont {
  position: relative;
  z-index: 3;
}
.message-cont__inner {
  position: relative;
}
@media (max-width: 1023px) {
  .message-cont__inner {
    background: #fff;
    padding-top: clamp(3rem, 8vw, 10rem);
    padding-bottom: clamp(3rem, 8vw, 10rem);
    padding-right: min(10%, 4rem);
    padding-left: min(10%, 4rem);
  }
}
@media (min-width: 1024px) {
  .message-cont__inner::after {
    content: "";
    background: #21374e;
    width: min(65vw,1060px);
    height: min(65vw,1060px);
    position: absolute;
    bottom: min(-7.5vw,-144px);
    left: min(-7.5vw,-144px);
    z-index: -1;
  }
}
@media (min-width: 1024px) {
  .message-cont__txt {
    background: #fff;
    max-width: 1120px;
    width: 77.77%;
    padding-top: clamp(6rem, 8vw, 9rem);
    padding-right: clamp(5rem, 12vw, 14rem);
    padding-left: clamp(5rem, 9vw, 10rem);
    padding-bottom: clamp(6rem, 8vw, 8rem);
    z-index: 2;
    position: relative;
  }
}

.message-name {
  text-align: right;
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.42em;
  line-height: 1.4;
  letter-spacing: 0.2em;
}
@media (min-width: 576px) {
  .message-name {
    font-size: 1.33em;
  }
}
@media (min-width: 1024px) {
  .message-name {
    font-size: 1.25em;
  }
}

.message-cont__img {
  z-index: 4;
}
@media (min-width: 1024px) {
  .message-cont__img {
    position: absolute;
    max-width: 440px;
    width: 30.55%;
    top: min(15vw,15rem);
    right: 0;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  .message-cont__img {
    float: right;
    margin-top: 2rem;
    margin-left: 5%;
    margin-bottom: 1.5rem;
    max-width: 280px;
    width: 40%;
  }
}
@media (max-width: 575px) {
  .message-cont__img {
    position: absolute;
    width: clamp(160px, 60%, 200px);
    top: clamp(-80px, -35vw, -120px);
    left: 50%;
    transform: translateX(-50%);
  }
}
.message-cont__img__inner {
  margin-left: auto;
  margin-right: auto;
}
.message-cont__img figcaption {
  margin-top: 0.7rem;
}
.message-cont__img__caption {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.message-cont__bg {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  z-index: 2;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .message-cont__bg {
    right: 0;
    width: 70%;
    height: 100%;
  }
}
@media (max-width: 1023px) {
  .message-cont__bg {
    width: 100%;
    height: 40%;
    left: 0;
  }
}
.message-cont__bg__inner {
  position: relative;
  height: 100%;
}
.message-cont__bg__inner::after {
  content: "";
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.message-cont__bg__inner > * {
  height: 100%;
}

/* philosophy */
.philosophy-cont {
  position: relative;
  padding-top: clamp(6rem, 9vw, 12rem);
  padding-bottom: clamp(8rem, 22vw, 24rem);
  overflow: hidden;
}
.philosophy-cont::before {
  position: absolute;
  top: 0;
  content: "";
  background: #fff;
  width: 100%;
  height: 30%;
  z-index: 2;
}
@media (min-width: 768px) {
  .philosophy-cont::before {
    height: 40%;
  }
}

.philosophy-cont__inner {
  position: relative;
  padding: clamp(2rem, 8vw, 6rem) clamp(4%, 8vw, 12rem);
  background: #fff;
  z-index: 4;
  border: 1px solid #c9ced2;
}
.philosophy-cont__inner .txt {
  line-height: 2;
}
.philosophy-cont__inner .txt .catch {
  line-height: 1.5;
  font-size: clamp(15px, 3.99vw, 20px);
}

.philosophy-cont__img {
  position: absolute;
  width: 100%;
  height: 70%;
  bottom: 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .philosophy-cont__img {
    height: 60%;
  }
}
.philosophy-cont__img__inner {
  position: relative;
  height: 100%;
}
.philosophy-cont__img__inner picture {
  height: 100%;
}

/* company */
.company-cont {
  z-index: 2;
  position: relative;
  padding-top: clamp(4rem, 8vw, 10rem);
  padding-bottom: clamp(5rem, 9vw, 12rem);
}
.company-cont__bg {
  display: block;
  width: 100%;
  height: clamp(200px, 40vw, 600px);
  position: relative;
}
.company-cont__bg__img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
}
.company-cont__bg__img__inner {
  position: relative;
  height: 100vw;
}

/* accessmap */
.accessmap-box {
  width: 100%;
}
.accessmap-box:not(:last-of-type) {
  margin-bottom: clamp(5rem, 8vw, 8rem);
}
.accessmap-box .txt {
  margin-top: 1rem;
}
.accessmap__map {
  width: 100%;
}
.accessmap__inner {
  position: relative;
  padding: 0;
  height: 0;
  overflow: hidden;
  background-color: #fff;
}
@media (min-width: 1024px) {
  .accessmap__inner {
    padding-bottom: 40%;
  }
}
@media (max-width: 1023px) {
  .accessmap__inner {
    padding-bottom: 360px;
  }
}
.accessmap__inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  vertical-align: bottom;
}

/* news-cont */
.news-cont {
  padding-top: clamp(4rem, 8vw, 10rem);
  padding-bottom: clamp(5rem, 9vw, 12rem);
  background: #f6f6f6;
}
.news-cont .common-table th {
  font-family: 'Roboto', sans-serif;
}

/* blog-cont */
.blog-cont {
  padding-top: clamp(4rem, 8vw, 10rem);
  padding-bottom: clamp(5rem, 9vw, 12rem);
  background: #F8F9FA;
}
.blog-cont .l-cont {
  position: relative;
}
.blog-cont .btn-wrap {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 360px;
}
.blog-cont .btn-wrap button {
  font-size: 1.28em;
}
@media (min-width: 576px) {
  .blog-cont .btn-wrap button {
    font-size: 1.2em;
  }
}
@media (min-width: 1024px) {
  .blog-cont .btn-wrap button {
    font-size: 1.12em;
  }
}

.blog-cont__box2-list {
  margin-bottom: clamp(3rem, 4vw, 4rem);
}
@media (min-width: 1024px) {
  .blog-cont__box2-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4.16%;
  }
  .blog-cont__box2-list > * {
    width: 47.91%;
  }
}