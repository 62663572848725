@use "../_variables/variables" as *;
@use "break-points" as *;
//	utility
//---------------------------------------------------

// clearfix
@mixin clearfix(){
	&::after{
		display: block;
		content: "";
		clear: both;
	}
}

// scroll-bar
@mixin scroll-bar {
	scrollbar-width: thin;
  scrollbar-color: $m-gray $white;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background:transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: $m-gray;
		border-radius: 8px;
		box-shadow: inset 0 0 0 1px $white;
	}
}

//	flex
//---------------------------------------------------

@mixin flex-between {
	display:flex;
	justify-content: space-between;
}

@mixin flex-around {
	display:flex;
	justify-content: space-around;
}

@mixin flex-end {
	display:flex;
	justify-content: flex-end;
}

@mixin flex-column {
	display:flex;
	flex-direction:column;
}
@mixin flex-r-reverse {
	display:flex;
	flex-direction: row-reverse;
}
@mixin flex-c-reverse {
	display:flex;
	flex-direction:column-reverse;
}

@mixin flex-wrap {
	display: flex;
	flex-wrap:wrap;
}




//	contents
//---------------------------------------------------
@mixin cont-padding($pt_min:4rem,$pt_value:8vw,$pt_max:10rem,$pb_min:5rem,$pb_value:9vw,$pb_max:12rem) {
	padding-top: clamp($pt_min, $pt_value,$pt_max);
	padding-bottom: clamp($pb_min, $pb_value, $pb_max);
}

@mixin cont-margin-bottom($mb_min:4rem,$mb_value:8vw,$mb_max:10rem) {
	margin-bottom: clamp($mb_min, $mb_value, $mb_max);
}


//	dafault_parts
//---------------------------------------------------
@mixin m-a {
	margin-left: auto;
	margin-right:auto;
}

@mixin dec-line {
	text-decoration: underline !important;
}
@mixin dec-none {
	text-decoration: none !important;
}

//	border-radius
//---------------------------------------------------
@mixin radius($radius:5px) {
	border-radius:$radius;
}
