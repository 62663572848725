@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/*====================================================================

index.css

=====================================================================*/

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
$top_sp_hight: floor-decimal(calc(720 / 480) * 100) * 1vw; //スマホの高さ
$top_tb_hight: 100vw;
$top_pc_hight: 100vh;
.main-view {
	position: relative;
	@include media-breakpoint-up(md) {
		height: $top_tb_hight;
	}
	@include media-breakpoint-up(lg) {
		height: $top_pc_hight;
	}
	@include media-breakpoint-down(sm) {
		height: $top_sp_hight;
	}
	& .l-cont {
		display: flex;
		align-items: center;
		justify-content: end;
		height: 100%;
		z-index: z-index(module, part02);
		@include media-breakpoint-down(xs) {
			justify-content: center;
		}
	}
}

.main-view__bg {
	position: relative;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		height: $top_tb_hight;
	}
	@include media-breakpoint-up(lg) {
		height: 100%;
	}
	@include media-breakpoint-down(sm) {
		height: $top_sp_hight;
	}
	&__img {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: z-index(module, part04);
		@include media-breakpoint-up(md) {
			height: $top_tb_hight;
		}
		@include media-breakpoint-up(lg) {
			height: $top_pc_hight;
		}
		@include media-breakpoint-down(sm) {
			height: $top_sp_hight;
		}
		&__inner {
			height: 100%;
			img {
				transform: scale(1.1, 1.1);
			}
		}
	}
}

.main-view__inner {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: z-index(module, part04);
}

.main-view__txt {
	z-index: z-index(module, part01);
	width: 100%;
}

.main-view {
	position: relative;
	&__filter {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: $white;
		z-index: z-index(module, part03);
		opacity: 0;
	}
	& .l-cont {
		z-index: z-index(module, part02);
		position: relative;
		width: 100%;
		height: 100%;
		max-width: none;
		padding: 0;
	}
	&__catch {
		text-align: center;
		width: 100%;
		z-index: z-index(module, part01);
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		text-align: center;
		&__inner {
			color: $main_c;
			background: transparent;
			padding: 1.5rem 4% 2rem;
			display: inline-block;
			@include transition(all, 0.6, ease-in, 0);
			@include media-breakpoint-up(lg) {
				padding: 1.5rem 5rem 2rem;
			}
			&.display {
				color: $white;
				background: rgba($main_c, 0.7);
			}
			em {
				@include f-family(font02);
				@include l-sp(0.05);
				font-style: normal;
				display: block;
				@include f-s_xs(2, 30);
				line-height: 1;
				@include media-breakpoint-up(xl) {
					@include f-size(60);
				}
			}
		}
	}
	&__txt {
		padding: 3.5rem  0 4rem 5rem;
		background-color: rgba($color: $main_c, $alpha: 0.91);
		max-width: 1030px;
	}
}

.catch-01 {
	position: relative;
	text-transform: uppercase;
	@include f-family(font03,serif);
	@include fs-xxs(30, 84);
	color: $white;
	@include line-h(1.5);
	@include l-sp(0.05em);
	@include f-w(400);
	@include media-breakpoint-up(xl) {
		@include f-size(84);
	}
	@include media-breakpoint-down(xs) {
		text-align: center;
	}
	&__letter {
		display: inline-block;
		line-height: 1em;
	}
}

.catch-02,.catch-03 {
	position: relative;
	display: block;
	padding-top: 0.1em;
	padding-bottom: 0.15em;
	overflow: hidden;
	@include fs-xs(10, 22);
	color: $white;
	@include l-sp(0.13em);
	@include line-h(1.5);
	@include f-w(500);
	@include media-breakpoint-up(xl) {
		@include f-size(22);
	}
	@include media-breakpoint-down(xs) {
		text-align: center;
	}
	&__letter {
		display: inline-block;
		line-height: 0.1em;
	}
}

/* scroll */
.scroll-line {
	position: absolute;
	//left: unquote("min(2%,4rem)");
	left: min(2%, 4rem);
	bottom: -2.5rem;
	writing-mode: vertical-rl;
	z-index: z-index(module, part01);
	a {
		@include dec-none;
		@include f-family(font02);
		@include f-w(400);
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: $white;
		@include transition;
		&:hover {
			color: $main_c;
		}
	}
	&__txt {
		@include l-sp(0);
	}
	span {
		content: "";
		inline-size: 5rem;
		block-size: 1px;
		background: transparent;
		display: block;
		position: relative;
		overflow: hidden;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			background: $main_c;
			@include transition;
			block-size: 1px;
			inline-size: 100%;
			animation: scroll-line 1.75s cubic-bezier(1, 0, 0, 1) infinite;
		}
	}
}

@keyframes scroll-line {
	0% {
		transform: scale(1, 0);
		transform-origin: 0 0;
	}
	50% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}
	51% {
		transform: scale(1, 1);
		transform-origin: 0 100%;
	}
	100% {
		transform: scale(1, 0);
		transform-origin: 0 100%;
	}
}

.simpleParallax {
	height: 100%;
}

/* common
----------------------------------------------------------------*/

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/

//about-cont
.about-cont {
	position: relative;
	padding-top: clamp(6rem, 10vw, 10rem);
	padding-bottom: clamp(6rem, 10vw, 10rem);
	overflow: hidden;
	@include media-breakpoint-up(lg) {
		padding-bottom: clamp(10rem, 12vw, 14rem);
	}
	&__inner {
		position: relative;
		display: flex;
		@include media-breakpoint-down(md) {
			@include m-a;
			flex-direction: column-reverse;
			padding-left: map-get($contmargin, tb);
			padding-right: map-get($contmargin, tb);
		}
		@include media-breakpoint-down(sm) {
			padding-left: map-get($contmargin, sp);
			padding-right: map-get($contmargin, sp);
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			width: 50%;
			@include flex-end;
			align-items: center;
		}
		&__inner {
			@include media-breakpoint-up(lg) {
				padding-right: 8vw;
				padding-left: 6vw;
				width: unquote("min(50vw,740px)");
			}
			@include media-breakpoint-up(xl) {
				padding-left: map-get($contmargin, pc);
			}
			@include media-breakpoint-up(xxl) {
				width: unquote("min(60vw,800px)");
			}
		}
	}
}

.about-cont__img {
	position: relative;
	@include media-breakpoint-up(lg) {
		width: 50%;
	}
	@include media-breakpoint-down(md) {
		padding-bottom: clamp(2.5em, 4vw, 4rem);
		margin-bottom: 1.5rem;
	}
	&::after {
		@include media-breakpoint-down(md) {
			position: absolute;
			display: block;
			left: 10%;
			bottom: 0;
			content: "";
			width: 100%;
			height: calc(100% - 6vw);
			background: $main_c;
			z-index: 0;
		}
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: center;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
		}
		figure {
			position: relative;
			z-index: z-index(module, part03);
			@include media-breakpoint-up(lg) {
				@include aspect-img(5, 4);
				overflow: visible;
				width: unquote("min(45vw,864px)");
			}
			@include media-breakpoint-down(md) {
				@include aspect-img(3, 2);
			}
			& > div {
				position: relative;
				z-index: z-index(module, part03);
			}
			&::after {
				position: absolute;
				display: block;
				content: "";
				width: 100%;
				height: 100%;
				background: $main_c;
				z-index: z-index(module, part04);
				@include media-breakpoint-up(lg) {
					left: 12%;
					top: 6vw;
				}
				@include media-breakpoint-up(xxl) {
					top: 6rem;
				}
			}
		}
	}
	img {
		width: 100%;
		display: block;
	}
}

.freecont01-cont {
	overflow: hidden;
	position: relative;
	background: $o-white;
	@include cont-padding;
	&__tit {
		@include cont-tit;
		margin-bottom: calc(2rem + 2em);
		text-align: center;
	}
	&__txt {
		margin-bottom: clamp(3rem, 4vw, 4rem);
		@include m-a;
		max-width: map-get($container-max-widths, lg);
	}
}

/* freecontent02 */
.freecont02-cont {
	overflow: hidden;
	position: relative;
	@include cont-padding;
	&__tit {
		@include cont-tit;
		margin-bottom: calc(2rem + 2em);
		text-align: center;
	}
	&__txt {
		margin-bottom: clamp(3rem, 4vw, 4rem);
		@include m-a;
		max-width: map-get($container-max-widths, lg);
	}
}

/* works-cont */
.works-cont {
	@include cont-padding;
	.l-cont {
		position: relative;
	}
	&__txt {
		@include media-breakpoint-up(xl) {
			display: flex;
			align-items: center;
		}
		margin-bottom: calc(2rem + 0.75em);
		.cont-tit {
			@include media-breakpoint-up(xl) {
				padding-right: 3em;
			}
			@include media-breakpoint-down(lg) {
				margin-bottom: calc(1rem + 0.3em);
			}
		}
	}
	.btn-wrap {
		@include m-a;
		width: 100%;
		max-width: 360px;
		& button {
			@include f-em(18);
		}
	}
}

/*---------------------------------------------------------
 staff
---------------------------------------------------------*/
.staff-cont {
	padding-top: clamp(6rem, 10vw, 10rem);
	padding-bottom: clamp(6rem, 10vw, 10rem);
	position: relative;
	@include bg_dot($m-gray, $o-white);
	z-index: z-index(module, part04);
	@include media-breakpoint-down(xs) {
		padding-top: clamp(10rem, 40vw, 20rem);
	}
	&__llst {
		& > * {
			&:not(:last-of-type) {
				padding-bottom: clamp(2rem, 3vw, 3rem);
				@include media-breakpoint-up(md) {
					padding-bottom: clamp(2rem, 6vw, 6rem);
				}
			}
		}
	}
	.none-txt {
		@include media-breakpoint-up(md) {
			text-align: center;
		}
	}
}

/* staff-box */
$staff_img_width: 28.33%; //画像の幅
$staff_txt_width: 60%; //テキストの幅

.staff-box {
	padding-top: clamp(2rem, 3vw, 3rem);
	&:not(:last-of-type) {
		padding-bottom: clamp(2rem, 3vw, 3rem);
	}

	@include media-breakpoint-up(md) {
	}
	&__inner {
		&.is-left {
			@include media-breakpoint-up(md) {
				display: grid;
				grid-template-columns: $staff_img_width $staff_txt_width;
				grid-template-rows: auto 1fr;
				column-gap: 11.66%;
				.staff-box__img {
					grid-column: 1 / 2;
				}
				.staff-box__tit {
					grid-column: 2 / 3;
				}
				.staff-box__txt {
					grid-column: 2 / 3;
				}
			}
		}
		&.is-right {
			@include media-breakpoint-up(md) {
				display: grid;
				grid-template-columns: $staff_txt_width $staff_img_width;
				grid-template-rows: auto 1fr;
				column-gap: 11.66%;
				.staff-box__img {
					grid-column: 2 / 3;
				}
				.staff-box__tit {
					grid-column: 1 / 2;
				}
				.staff-box__txt {
					grid-column: 1 / 2;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			max-width: 480px;
			@include m-a;
		}
	}
	&__tit {
		color: $black;
		@include f-w(500);
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-bottom: clamp(1rem, 2vw, 1.5rem);
		border-bottom: 1px solid $m-gray;
		@include f-s_xs(1.07, 6);
		@include media-breakpoint-up(md) {
			grid-row: 1 / 2;
			@include f-s_lg(1.25, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(sm) {
			width: calc(100% - 1rem);
			margin-bottom: 2rem;
		}
		&__inner {
			display: block;
			position: relative;
			&.light-box {
				overflow: visible !important;
			}
			@include media-breakpoint-down(sm) {
				width: 100%;
				height: 66.66%;
			}
			&::before {
				position: absolute;
				top: 1rem;
				left: 1rem;
				content: "";
				background: $main_c;
				width: 100%;
				height: 100%;
				@include media-breakpoint-up(md) {
					top: 2rem;
					left: 2rem;
				}
			}
			figure {
				@include media-breakpoint-up(md) {
					@include aspect-img(1, 1);
				}
				@include media-breakpoint-down(sm) {
					@include aspect-img();
				}
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
	}
}

/*---------------------------------------------------------
 qa
---------------------------------------------------------*/
.qa-cont {
	padding-top: clamp(6rem, 10vw, 10rem);
	padding-bottom: clamp(6rem, 10vw, 10rem);
	background: $p-gray;
	&__llst {
		counter-reset: qa-num;
		& > * {
			&:not(:last-of-type) {
				padding-bottom: clamp(2rem, 3vw, 3rem);
				@include media-breakpoint-up(md) {
					padding-bottom: clamp(2rem, 6vw, 6rem);
				}
			}
		}
	}
	.none-txt {
		@include media-breakpoint-up(md) {
			text-align: center;
		}
	}
}
.qa-box {
	&__tit {
		padding-left: calc(2em + 2rem);
		color: $black;
		position: relative;
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-bottom: clamp(1rem, 2vw, 1.5rem);
		border-bottom: 1px solid $m-gray;
		@include media-breakpoint-up(xl) {
			padding-left: 4.5rem;
		}
		span {
			@include f-w(500);
			@include f-s_xs(1.14, 4);
			@include media-breakpoint-up(xl) {
				@include f-size(22);
			}
		}
		&::before {
			counter-increment: qa-num;
			content: "Q." counter(qa-num);
			position: absolute;
			@include f-family(font02);
			top: 0.2em;
			left: 0;
			@include line-h(1);
			color: $main_c;
			@include f-s_xs(2, 8);
			@include l-sp(0);
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
	.txt {
	}
}

/* message */
.message-cont {
	padding-top: clamp(6rem, 10vw, 10rem);
	padding-bottom: clamp(8rem, 20vw, 24rem);
	position: relative;
	background: $white;
	z-index: z-index(module, part04);
	overflow: hidden;
	@include media-breakpoint-down(md) {
		background: $main_c;
	}
	&.is-imguse {
		@include media-breakpoint-down(xs) {
			padding-top: clamp(10rem, 40vw, 20rem);
		}
		.message-cont__inner {
			@include media-breakpoint-down(xs) {
				padding-top: clamp(11rem, 50vw, 16rem);
			}
		}
	}
	& .l-cont {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__inner {
		position: relative;
		@include media-breakpoint-down(md) {
			background: $white;
			padding-top: clamp(3rem, 8vw, 10rem);
			padding-bottom: clamp(3rem, 8vw, 10rem);
			padding-right: unquote("min(10%, 4rem)");
			padding-left: unquote("min(10%, 4rem)");
		}
		&::after {
			@include media-breakpoint-up(lg) {
				content: "";
				background: $main_c;
				width: unquote("min(65vw,1060px)");
				height: unquote("min(65vw,1060px)");
				position: absolute;
				bottom: unquote("min(-7.5vw,-144px)");
				left: unquote("min(-7.5vw,-144px)");
				z-index: -1;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			background: $white;
			max-width: 1120px;
			width: 77.77%;
			padding-top: clamp(6rem, 8vw, 9rem);
			padding-right: clamp(5rem, 12vw, 14rem);
			padding-left: clamp(5rem, 9vw, 10rem);
			padding-bottom: clamp(6rem, 8vw, 8rem);
			z-index: z-index(module, part03);
			position: relative;
		}
	}
}

.message-name {
	text-align: right;
	margin-top: 2rem;
	@include f-w(600);
	@include f-em(20);
	@include line-h(1.4);
	@include l-sp(0.2em);
}

.message-cont__img {
	z-index: z-index(module, part01);
	@include media-breakpoint-up(lg) {
		position: absolute;
		max-width: 440px;
		width: 30.55%;
		top: unquote("min(15vw,15rem)");
		right: 0;
	}
	@include media-breakpoint-between(sm, md) {
		float: right;
		margin-top: 2rem;
		margin-left: 5%;
		margin-bottom: 1.5rem;
		max-width: 280px;
		width: 40%;
	}
	@include media-breakpoint-down(xs) {
		position: absolute;
		width: clamp(160px, 60%, 200px);
		top: clamp(-80px, -35vw, -120px);
		left: 50%;
		transform: translateX(-50%);
	}
	&__inner {
		@include m-a;
	}
	figcaption {
		margin-top: 0.7rem;
	}
	&__caption {
		width: 75%;
		@include m-a;
	}
}

.message-cont__bg {
	position: absolute;
	top: 0;
	content: "";
	display: block;
	z-index: z-index(module, part03);
	overflow: hidden;
	@include media-breakpoint-up(lg) {
		right: 0;
		width: 70%;
		height: 100%;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		height: 40%;
		left: 0;
	}
	&__inner {
		position: relative;
		height: 100%;
		&::after {
			content: "";
			background: rgba($white, 0.4);
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
		& > * {
			height: 100%;
		}
	}
}

/* philosophy */
$height_bg_sp: 70%; //sp表示の際の背景の高さ
$height_bg_pc: 60%; //PC表示の際の背景の高さ
$height_bg_white_sp: 100% - $height_bg_sp; //sp表示の際の背景の高さ
$height_bg_white_pc: 100% - $height_bg_pc; //PC表示の際の背景の高さ

.philosophy-cont {
	position: relative;
	padding-top: clamp(6rem, 9vw, 12rem);
	padding-bottom: clamp(8rem, 22vw, 24rem);
	overflow: hidden;
	&::before {
		position: absolute;
		top: 0;
		content: "";
		background: $white;
		width: 100%;
		height: $height_bg_white_sp;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			height: $height_bg_white_pc;
		}
	}
}

.philosophy-cont__inner {
	position: relative;
	padding: clamp(2rem, 8vw, 6rem) clamp(4%, 8vw, 12rem);
	background: $white;
	z-index: z-index(module, part01);
	border: 1px solid $m-gray;
	.txt {
		@include line_h(2);
		.catch {
			@include line_h(1.5);
			@include fs_xs(15, 20);
		}
	}
}

.philosophy-cont__img {
	position: absolute;
	width: 100%;
	height: $height_bg_sp;
	bottom: 0;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		height: $height_bg_pc;
	}
	&__inner {
		position: relative;
		height: 100%;
		picture {
			height: 100%;
		}
	}
}

/* company */
.company-cont {
	z-index: z-index(module, part03);
	position: relative;
	@include cont-padding;
	&__bg {
		//企業理念を使用しない場合
		display: block;
		width: 100%;
		height: clamp(200px, 40vw, 600px);
		position: relative;
		&__img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			overflow: hidden;
			&__inner {
				position: relative;
				height: 100vw;
			}
		}
	}
}

/* accessmap */
.accessmap {
	&-box {
		width: 100%;
		&:not(:last-of-type) {
			margin-bottom: clamp(5rem, 8vw, 8rem);
		}
		.txt {
			margin-top: 1rem;
		}
	}
	&__map {
		width: 100%;
	}
	&__inner {
		position: relative;
		padding: 0;
		height: 0;
		overflow: hidden;
		background-color: $white;
		@include media-breakpoint-up(lg) {
			padding-bottom: 40%;
		}
		@include media-breakpoint-down(md) {
			padding-bottom: 360px;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
			vertical-align: bottom;
		}
	}
}

/* news-cont */
.news-cont {
	@include cont-padding;
	background: $p-gray;
	.common-table {
		th {
			@include f-family(font02);
		}
	}
}

/* blog-cont */
.blog-cont {
	@include cont-padding;
	background: $o-white;
	.l-cont {
		position: relative;
	}
	.btn-wrap {
		@include m-a;
		width: 100%;
		max-width: 360px;
		& button {
			@include f-em(18);
		}
	}
}
.blog-cont__box2-list {
	margin-bottom: clamp(3rem, 4vw, 4rem);
	@include media-breakpoint-up(lg) {
		@include flex-wrap;
		column-gap: 4.16%;
		& > * {
			width: 47.91%;
		}
	}
}
